import React from "react"
import { Helmet } from "react-helmet"
import "./events.css"
import "../components/partner.css"
import 'bootstrap/dist/css/bootstrap.min.css'

import CeeLogo from "../images/logos/cee-logo.png"
import EmmaHead from "../images/gatsby-icon.png"
import event_img from '../images/events-img/card-img/elas-senec-min.jpg'
import MyFooter from "../components/footer.js"
import MyNavbar from "../components/navbar.js"
import EventBanner from "../components/event-banner.js"

const EventsPage = () => (
  <div className="index">
    <Helmet>
      <title>Elas na Senec</title>
      <link rel="icon" href={EmmaHead} />
    </Helmet>
      <MyNavbar />
      <EventBanner 
        mobile_img = {event_img}
        img = {event_img}
        name = "Elas na SenEC: 1o Workshop de Programação em Blocos (CEE/Poli-USP)"
        date = "Setembro/2019"
        description = "O Workshop de Programação em Blocos visa promover o contato das alunas com a lógica e a programação em blocos, através da interface de programação Scratch. As aulas são baseadas em conteúdo expositivo (através de slides) e interativo (no formato de quiz através do site Kahoot), sendo as alunas acompanhadas durante a resolução de exercícios.        O evento, realizado em parceria com o Centro Acadêmico de Engenharia Elétrica e de Computação da Escola Politécnica (CEE/Poli-USP) durante a Semana de Engenharia Elétrica e de Computação (SenEC), contou com uma roda de conversa sobre carreira e ingresso na Escola Politécnica e o primeiro oferecimento do workshop de programação em blocos. Teve duração de 8h e atingiu 41 meninas do ensino médio da Escola Estadual Ministro Costa Manso."
      />
      <div className="partner-container">
        <div className="partner-title">PARCERIAS</div>
        <div className="partner-flex">
            <div className="partner-element">
                <img src={CeeLogo} alt="" className="partner-logo"/>
                <p className="partner-name">CEE</p>
            </div>
        </div>
      </div>
      <MyFooter />
  </div>
)

export default EventsPage
